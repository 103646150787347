import React from "react"
import { WhatsAppOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import { FaHandPointRight } from "react-icons/fa" // Importa el ícono de dedo señalando a la derecha
import styles from "./WhatsAppButton.module.css" // Importa el CSS module
import { TrackGoogleAnalyticsEvent } from "../../Tracking/GoogleTracking"

const WhatsAppButton = () => {
  const baseURL = process.env.NEXT_PUBLIC_WA_URL || "https://wa.me/"
  const fivaNumber = process.env.NEXT_PUBLIC_FIVA_NUMBER || "+34624644137"
  const message =
    "¡Hola! Estoy interesado en conocer más sobre sus servicios. ¿Podrían ayudarme, por favor?"
  const whatsAppURL = `${baseURL}${fivaNumber}?text=${encodeURIComponent(
    message
  )}`

  const trackAnalyticsEvent = () => {
    TrackGoogleAnalyticsEvent(
      "WhatsAppChat",
      "WhatsAppChat",
      "WhatsAppChat"
    )
  }
  return (
    <div className={styles.container}>
      <Tooltip
        title={
          <div className={styles.tooltipContent}>
            ¿Tiene alguna duda? Estamos aquí para ayudarte 👉
          </div>
        }
        placement="left"
      >
        <a
          href={whatsAppURL}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.whatsappButton}
          aria-label="Chatea con nosotros en WhatsApp"
          onClick={trackAnalyticsEvent}
        >
          <WhatsAppOutlined className={styles.fontSize} />
        </a>
      </Tooltip>
    </div>
  )
}

export default WhatsAppButton
